<template>
  <div class="layout-main">
    <SiteHeader/>
    <div class="layout-main__content">
      <router-view/>
    </div>
    <div class="layout-main__footer"></div>
  </div>
</template>

<script>
import SiteHeader from '@/components/site/SiteHeader.vue';

export default {
  name: 'MainLayout',

  components: {
    SiteHeader,
  },
};
</script>

<style lang="scss">
.layout-main {
  margin: 0 auto;
  max-width: 1100px;

  &__footer {
    margin-top: 48px;
  }
}
</style>
