<template>
  <div class="ip-radio-btn">
    <IPButton
      :variant="modelValue === option[attributeValue] ? 'primary' : 'default-outline'"
      v-for="option in options"
      square
      size="sm"
      @click="select(option)"
      :key="option[attributeValue]"
    >
      {{ option[attributeLabel] }}
    </IPButton>
  </div>
</template>

<script>
import IPButton from '@/core/ui/buttons/IPButton.vue';

export default {
  name: 'IPRadioBtn',

  components: {
    IPButton,
  },

  props: {
    modelValue: null,

    options: {
      type: Array,
      default() {
        return [];
      },
    },

    attributeLabel: {
      type: String,
      default: 'label',
    },

    attributeValue: {
      type: String,
      default: 'value',
    },
  },

  setup(props, { emit }) {
    function select(item) {
      emit('update:modelValue', item[props.attributeValue]);
    }

    return {
      select,
    };
  },
};
</script>
