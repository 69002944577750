<template>
  <div class="ip-attribute" :class="`ip-attribute_${type}`">
    <IconAttencion :type="type" class="ip-attribute__icon"/>
    <slot/>
  </div>
</template>

<script>
import IconAttencion from '@/core/ui/icons/IconAttencion';

export default {
  name: 'IPAttribute',

  components: {
    IconAttencion,
  },

  props: {
    type: {
      type: String,
      default: 'info',
      validate(value) {
        return [
          'alarm',
          'warning',
          'success',
          'info',
        ].includes(value);
      },
    },
  },
};
</script>

<style lang="scss">
.ip-attribute {
  width: fit-content;
  padding: 8px 12px;
  border-radius: 4px;

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  display: flex;
  align-items: center;

  &__icon {
    margin-right: 8px;
  }

  &_alarm {
    background: #FFE9E6;
    color: #FF1F00;
  }

  &_warning {
    background: #FFF5E9;
    color: #FFA63B;
  }

  &_success {
    background: #E6FAEE;
    color: #00C853;
  }

  &_info {
    background: #F3F3F4;
    color: #161E2F;
  }
}
</style>
