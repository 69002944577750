<template>
<div class="print-study">
  <div class="print-study__head">
    <div class="print-study__name">
      {{ item.name }}
    </div>
    <div class="print-study__included-planned">
      {{
        t('study.labels.included_planned', {
          included: item.current_enrollment,
          planned: item.expected_total_enrollment,
        })
      }}
    </div>
  </div>

  <div class="print-study__timeline">
    <IPDateProgress
      :date-from="item.date_planned_start"
      :date-to="item.date_planned_end"
    />
  </div>

  <div class="print-study__row">
    <IPStatsProgress
      class="print-study__column"
      :max-total="item.counters.patient_active_statuses.total_count"
      :items="item.counters.patient_active_statuses.counters"
    />
  </div>

  <div class="print-study__row">
    <IPStatsProgress
      class="print-study__column"
      :max-total="item.counters.patient_screen_statuses.total_count"
      :items="item.counters.patient_screen_statuses.counters"
    />

    <IPStatsProgress
      class="print-study__column"
      :max-total="item.counters.ae_sae.total_count"
      :items="item.counters.ae_sae.counters"
    />
  </div>

  <div class="print-study__info" v-if="view">
    <!-- Пациенты -->
    <IPCard v-if="view.patients_info">
      <div class="ip-card__title ym-hide-content">
        <div>{{ t('study.card.patients') }}</div>
        <IPTag>{{ view.patients_info.total_count }}</IPTag>
      </div>

      <div class="ip-card__content ym-hide-content">
        <div class="ip-card__item" v-if="view.patients_info.screening">
          <div class="ip-card__item-label">
            {{ t(`study.status.screening`) }}
          </div>
          <div class="ip-card__item-value">
            {{ view.patients_info.screening }}
          </div>
        </div>

        <div class="ip-card__item ym-hide-content" v-if="view.patients_info.screenfail">
          <div class="ip-card__item-label">
            {{ t(`study.status.screenfail`) }}
          </div>
          <div class="ip-card__item-value">
            {{ view.patients_info.screenfail }}
          </div>
        </div>

        <div class="ip-card__item ym-hide-content" v-if="view.patients_info.enrolled">
          <div class="ip-card__item-label">
            {{ t(`study.status.enrolled`) }}
          </div>
          <div class="ip-card__item-value">
            {{ view.patients_info.enrolled }}
          </div>
        </div>

        <div class="ip-card__item ym-hide-content" v-if="view.patients_info.randomized">
          <div class="ip-card__item-label">
            {{ t(`study.status.randomized`) }}
          </div>
          <div class="ip-card__item-value">
            {{ view.patients_info.randomized }}
          </div>
        </div>

        <div class="ip-card__item ym-hide-content" v-if="view.patients_info.withdrawal">
          <div class="ip-card__item-label">
            {{ t(`study.status.withdrawal`) }}
          </div>
          <div class="ip-card__item-value">
            {{ view.patients_info.withdrawal }}
          </div>
        </div>

        <div class="ip-card__item ym-hide-content" v-if="view.patients_info.completed">
          <div class="ip-card__item-label">
            {{ t(`study.status.completed`) }}
          </div>
          <div class="ip-card__item-value">
            {{ view.patients_info.completed }}
          </div>
        </div>

        <div class="ip-card__item ym-hide-content" v-if="view.patients_info.other">
          <div class="ip-card__item-label">
            {{ t(`study.status.other`) }}
          </div>
          <div class="ip-card__item-value">
            {{ view.patients_info.other }}
          </div>
        </div>
      </div>
    </IPCard>

    <!-- Нежелательные явления (НЯ) -->
    <IPCard v-if="view.adverse_events_info">
      <div class="ip-card__title ym-hide-content">
        <div>{{ t('study.card.ae') }}</div>
        <IPTag>{{ view.adverse_events_info.total_count }}</IPTag>
      </div>

      <div class="ip-card__content">
        <div
          class="ip-card__item ym-hide-content"
          v-for="status in view.adverse_events_info.statuses"
          :key="status.status"
        >
          <div class="ip-card__item-label">{{ t(`study.status.${status.status}`) }}</div>
          <div class="ip-card__item-value">{{ status.count }}</div>
        </div>
      </div>

      <!-- Серьезные нежелательные явления (СНЯ) -->
      <div class="ip-card__title ym-hide-content">
        <div>{{ t('study.card.sae') }}</div>
        <IPTag>{{ view.saes_info.primary_count }}</IPTag>
      </div>

      <div class="ip-card__content">
        <div class="ip-card__item ym-hide-content" v-if="view.saes_info.sae_total_count">
          <div class="ip-card__item-label">
            {{ t(`study.status.sae_total_count`) }}
          </div>
          <div class="ip-card__item-value">{{ view.saes_info.sae_total_count }}</div>
        </div>
      </div>
    </IPCard>

    <!-- Запросы на уточнения -->
    <StudyCard
      :id="$route.params.id"
      :is-study="isStudy"
      title="study.card.queries"
      :items="view.queries_info.statuses"
      :count="view.queries_info.total_count"
      attribute="status"
    />

    <!-- Формы -->
    <StudyCard
      is-study
      title="study.card.forms"
      :items="view.forms_info.types"
      :count="view.forms_info.total_count"
      attribute="type"
    />
  </div>

  <div
    v-for="group in expand"
    :key="group.value"
    class="print-study__expand"
  >
    <div class="print-study__expand-name">{{ group.label }}</div>
    <template v-if="group.items && group.items.counters">
      <div
        class="print-study__expand-item"
        v-for="item in group.items.counters"
        :key="item.ts_number"
      >
        <div class="print-study__row print-study__row_between">
          <div class="print-study__column">
            {{ item.ts_number }} {{ item.principal_investigator }}
          </div>
          <div class="print-study__column" style="flex: 0">
            {{ item.total_count }}
          </div>
        </div>

        <IPStatsProgress
          minimal
          :items="item.counters"
          :max-total="group.value === 'activeItems' ? group.items.max_count : item.total_count"
          :total="item.total_count"
          show-legend
          show-legend-count
        />
      </div>
    </template>
  </div>
</div>
</template>

<script>
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import IPDateProgress from '@/core/ui/stats/IPDateProgress.vue';
import IPStatsProgress from '@/core/ui/stats/IPStatsProgress.vue';
import IPCard from '@/core/ui/IPCard.vue';
import IPTag from '@/core/ui/IPTag.vue';

import StudyCard from '@/components/study/StudyModal/StudyCard.vue';

import StudyViewRequest from '@/requests/study/StudyViewRequest';

export default {
  name: 'PrintStudy',

  components: {
    IPDateProgress,
    IPStatsProgress,
    IPCard,
    IPTag,
    StudyCard,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },

    expand: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const view = ref(null);
    const loadItems = async () => {
      const request = new StudyViewRequest(props.item.id);
      const response = await request.sendRequest();
      if (response.code === 200) {
        view.value = response.data;
      }
    };
    loadItems();

    watch(() => props.item.id, () => loadItems());

    return {
      t,
      view,
    };
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/kit/fonts";
@import "@/core/ui/assets/styles/kit/colors";

.print-study {
  visibility: hidden;
  height: 0;
  overflow: hidden;

  @media print {
    print-color-adjust: exact;
    visibility: visible;
    height: fit-content;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 0;

    &__head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &__name {
      @include font_header;
      color: $color_main_accent;
    }

    &__included-planned {
      @include font_subtitle;
    }

    &__timeline {
      margin-bottom: 20px;
    }

    &__row_between {
      justify-content: space-between;
    }

    &__row {
      width: 100%;
      display: flex;
      margin-bottom: 20px;
    }

    &__column {
      flex: 1;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    &__expand {
      margin: 10px 0;
    }

    &__expand-item {
      margin-bottom: 30px;
    }

    &__expand-name {
      @include font_title;
      margin-bottom: 10px;
    }

    .ip-card {
      margin: 20px 0;

      &__title {
        @include font_subtitle;
      }

      &__item, &__title {
        display: flex;
        justify-content: space-between;
        margin: 5px 0;
      }
    }
  }
}
</style>
