import Request from '@/core/services/request';

export default class StudyExpandRequest extends Request {
  constructor(id) {
    super('GET', `study/${id}/expand`, {
      sort_field: {
        value: 'total_count ',
      },
      sort_direction: {
        value: 'asc',
      },
    });
  }
}
