import Request from '@/core/services/request';

export default class SponsorCreateRequest extends Request {
  constructor() {
    super('POST', 'sponsors/employees', {
      first_name: {
        value: null,
      },
      last_name: {
        value: null,
      },
      patronymic_name: {
        value: null,
      },
      email: {
        value: null,
      },
      phone: {
        value: null,
      },
    }, 'EmployeeForm');
  }
}
