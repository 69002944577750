import Request from '@/core/services/request';

export default class LoginRequest extends Request {
  constructor() {
    super('POST', 'profile', {
      first_name: {
        value: null,
      },
      last_name: {
        value: null,
      },
      patronymic_name: {
        value: null,
      },
      phone: {
        value: null,
      },
      email: {
        value: null,
      },
      password: {
        value: null,
      },
      password_repeat: {
        value: null,
      },
    }, 'UserUpdateForm');
  }
}
