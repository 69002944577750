<template>
  <div class="web-nav">
    <div class="web-nav__items">
      <!-- Главная -->
      <IPButton
        variant="nav"
        :to="{ name: 'studies' }"
      >
        {{ t('common.menu.home') }}
      </IPButton>

      <!-- Пользователи -->
      <IPButton
        v-if="check('users_list')"
        variant="nav"
        :to="{ name: 'modal-users' }"
      >
        {{ t('common.menu.users') }}
      </IPButton>
    </div>

    <IPDropdown class="web-nav__profile">
      <IconUser/>
      {{ userLabel }}

      <template v-slot:items>
        <IPButton
          variant="dropdown-item"
          :to="{ name: 'modal-profile' }"
        >
          {{ t('profile.menu.profile') }}
        </IPButton>

        <!-- Выйти -->
        <div
          class="ip-dropdown__item"
          @click.self="handleLogout"
          @keyup="handleLogout"
        >
          {{ t('profile.menu.logout') }}
        </div>
      </template>
    </IPDropdown>

    <LanguageSwitcher/>
  </div>
</template>

<script>
import { computed } from 'vue';
import { userLabel } from '@/core/untils';
import { useStore } from 'vuex';
import { check } from '@/core/services/acl';
import { useI18n } from 'vue-i18n';

import IPButton from '@/core/ui/buttons/IPButton.vue';
import IPDropdown from '@/core/ui/IPDropdown.vue';
import LanguageSwitcher from '@/components/site/LanguageSwitcher.vue';

import IconUser from '@/core/ui/icons/IconUser.vue';

export default {
  name: 'WebNav',

  emits: ['logout'],

  components: {
    IPButton,
    IPDropdown,
    LanguageSwitcher,

    IconUser,
  },

  setup(props, { emit }) {
    const store = useStore();
    const user = computed(() => store.state.user.info);
    const { t } = useI18n();

    function handleLogout() {
      emit('logout');
    }

    return {
      t,
      userLabel: userLabel(user),
      check,
      handleLogout,
    };
  },
};
</script>

<style lang="scss">
.web-nav {
  flex: 1;
  display: flex;
  align-items: center;

  &__items {
    margin-left: 64px;
  }

  &__items > .ip-button_nav {
    margin-right: 32px;
  }

  &__profile {
    margin-left: auto;
    margin-right: 24px;
  }
}
</style>
