<template>
  <!-- Router Link -->
  <router-link
    :class="className"
    v-if="to"
    :to="to"
  >
    <slot/>
  </router-link>

  <!-- Link -->
  <a
    :class="className"
    v-else-if="href"
    :href="href"
  >
    <slot/>
  </a>

  <!-- Button -->
  <button
    :type="type"
    :class="className"
    :disabled="disabled"
    @click="handleClick"
    v-else
  >
    <slot/>
  </button>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'IPButton',

  emits: ['click'],

  props: {
    class: {
      type: String,
    },

    to: {
      type: [Object, String],
    },

    href: {
      type: String,
    },

    variant: {
      type: String,
      default: 'default',
      validate(value) {
        return [
          'default',
          'default-outline',
          'primary',
          'primary-outline',
          'link',
          'nav',
          'dropdown-item',
        ].includes(value);
      },
    },

    type: {
      type: String,
      default: 'button',
      validate(value) {
        return [
          'button',
          'submit',
        ].includes(value);
      },
    },

    size: {
      type: String,
      default: 'md',
      validator(value) {
        return ['sm', 'md'].includes(value);
      },
    },

    square: {
      type: Boolean,
    },

    round: {
      type: Boolean,
      default: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    adaptive: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const className = computed(() => {
      const styles = ['ip-button', `ip-button_${props.variant}`, `ip-button_${props.size}`];

      if (props.disabled) {
        styles.push('ip-button_disabled');
      }

      if (props.adaptive) {
        styles.push('ip-button_adaptive');
      }

      if (props.round) {
        styles.push('ip-button_round');
      }

      if (props.square) {
        styles.push('ip-button_square');
      }

      if (props.class) {
        styles.push(props.class);
      }

      return styles;
    });

    function handleClick() {
      emit('click');
    }

    return {
      className,
      handleClick,
    };
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/kit/fonts";
@import "@/core/ui/assets/styles/kit/colors";

.ip-button {
  user-select: none;
  cursor: pointer;
  border: none;
  color: $color_white;
  text-decoration: none;
  @include font_buttons;

  &_sm {
    border-radius: 10px;
    height: 32px;
    line-height: 32px;
    padding: 0 24px;

    .ip-button_round {
      border-radius: 20px;
    }
  }

  &_square {
    border-radius: 0;
  }

  &_md {
    border-radius: 40px;
    height: 48px;
    padding: 0 15px;
  }

  &_primary {
    background: #1B85FF;

    &:hover, &:active {
      background: #1878e5;
    }
  }

  &_primary-outline {
    background: $color_white;
    border: 1px solid $color_main_accent;
    color: $color_main_accent;

    &:hover, &:active {
      background: $color_main_accent;
      color: $color_white;
    }
  }

  &_default-outline {
    background: $color_white;
    border: 1px solid $color_light_grey;
    color: $color_main_black;

    &:hover, &:active {
      background: $color_main_accent;
      color: $color_white;
    }
  }

  &_link {
    @include font_details;

    background: none;
    text-decoration: none;
    padding: 0;
    color: $color_main_accent;
  }

  &_nav {
    @include font_body;

    background: none;
    text-decoration: none;
    padding: 0;
    color: $color_main_black;

    &:hover, &.router-link-active {
      color: $color_main_accent;
    }
  }

  &_dropdown-item {
    display: block;
    padding: 12px 0;
    color: $color_main_black;
    text-decoration: none;
    height: 14px;

    &:hover {
      color: $color_main_accent;
    }
  }

  &_adaptive {
    width: 100%;
  }

  &_disabled {
    background: #E6EAEE;
    cursor: default;

    &:hover {
      background: #E6EAEE;
    }
  }
}
</style>
