<template>
  <IPCard>
    <div class="ip-card__title ym-hide-content">
      <div>{{ t(title) }}</div>
      <IPTag>{{ count }}</IPTag>
    </div>

    <div class="ip-card__content">
      <div
        class="ip-card__item ym-hide-content"
        v-for="item in items"
        :key="item[attribute]"
      >
        <div class="ip-card__item-label">{{ t(`study.status.${item[attribute]}`) }}</div>
        <div class="ip-card__item-value">{{ item.count }}</div>
      </div>

      <IPButtonDownload
        v-if="id"
        variant="primary-outline"
        :url="downloadLink"
        size="sm"
        :adaptive="mob"
      >
        {{ t('common.buttons.download_table') }}
      </IPButtonDownload>
    </div>
  </IPCard>
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import IPCard from '@/core/ui/IPCard.vue';
import IPTag from '@/core/ui/IPTag.vue';
import IPButtonDownload from '@/core/ui/buttons/IPButtonDownload.vue';

export default {
  name: 'StudyCard',

  components: {
    IPCard,
    IPTag,
    IPButtonDownload,
  },

  props: {
    id: {
      type: String,
    },

    isStudy: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      required: true,
    },

    items: {
      type: Array,
    },

    count: {
      type: Number,
      default: 0,
    },

    attribute: {
      type: String,
      default: 'status',
    },

    exportType: {
      type: String,
    },

    mob: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const downloadLink = computed(() => {
      const param = props.isStudy ? 'studies' : 'trials';

      return `export/${param}/${props.id}/${props.exportType}`;
    });

    return {
      t,
      downloadLink,
    };
  },
};
</script>

<style scoped>

</style>
