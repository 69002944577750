<template>
  <div class="ip-date-progress" :class="{ 'ip-date-progress_mobile': mob }">
    <div class="ip-date-progress__dates">
      <div class="ip-date-progress__date-label ym-hide-content">{{ labelFrom }}</div>
      <div class="ip-date-progress__date-label ym-hide-content">{{ labelTo }}</div>
    </div>
    <div class="ip-date-progress__container">
      <div class="ip-date-progress__done" :style="`width: ${percent}%`"></div>
      <div class="ip-date-progress__current-day"></div>
    </div>
  </div>
</template>

<script>
import { isMobile } from '@/core/untils';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';

export default {
  name: 'IPDateProgress',

  props: {
    dateFrom: {
      type: String,
      required: true,
    },

    dateTo: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const mob = isMobile();
    const { locale } = useI18n();

    const momentFrom = computed(() => moment(props.dateFrom));
    const labelFrom = computed(() => {
      momentFrom.value.locale(locale.value);

      return momentFrom.value.format('D MMM Y');
    });

    const momentTo = computed(() => moment(props.dateTo));
    const labelTo = computed(() => {
      momentTo.value.locale(locale.value);

      return momentTo.value.format('D MMM Y');
    });

    const percent = computed(() => {
      const unixNow = moment().unix();
      const unixFrom = momentFrom.value.unix();
      const unixTo = momentTo.value.unix();

      const progressAll = unixTo - unixFrom;
      const progress = unixNow - unixFrom;

      return (progress / progressAll) * 100;
    });

    return {
      mob,
      labelFrom,
      labelTo,
      percent,
    };
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/kit/fonts";
@import "@/core/ui/assets/styles/kit/colors";

.ip-date-progress {
  width: 100%;

  &__dates {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &_mobile {
    .ip-date-progress__done {
      background: $color_blue_accent_1;
    }

    .ip-date-progress__current-day {
      background: $color_blue_accent_1;
    }
  }

  &__date-label {
    @include font_buttons;
    color: $color_grey_outline;
  }

  &__container {
    display: flex;
    align-items: center;
    flex: 1 0;
    height: 1px;
    background: $color_background_grey;
  }

  &__done {
    height: 2px;
    background: $color_mid_grey;
  }

  &__current-day {
    width: 12px;
    height: 12px;
    background: $color_mid_grey;
    border-radius: 50%;
  }
}
</style>
