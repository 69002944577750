<template>
  <label
    :for="id"
    class="ip-toggle"
  >
    <label :for="id" class="switch">
      <input
        v-model="value"
        :id="id"
        type="checkbox"
      >
      <span class="slider round"></span>
    </label>
    <span class="ip-toggle__label">{{ label }}</span>
  </label>
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'IPToggle',

  emits: ['change'],

  props: {
    modelValue: null,

    id: {
      type: String,
      required: true,
    },

    label: {
      type: String,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();

    const value = computed({
      get() {
        return props.modelValue;
      },

      set(value) {
        emit('update:modelValue', value);
        emit('change', value);
      },
    });

    return {
      t,
      value,
    };
  },
};
</script>

<style lang="scss">
.ip-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;

  &__label {
    margin-left: 10px;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;

  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}

input:checked + .slider {
  background-color: #277EFF;
}

input:focus + .slider {
  box-shadow: 0 0 1px #277EFF;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>

<i18n>
{
  "ru": {
    "quiz-modify-form-label-required": "Обязательный вопрос"
  }
}
</i18n>
