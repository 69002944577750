<template>
<div class="mobile-nav">
  <IconBurger class="mobile-nav__open" @click="open" />

  <div class="mobile-nav__content" v-if="isOpened">
    <IconClose class="mobile-nav__close" @click="close" />

    <div class="mobile-nav__items">
      <!-- Главная -->
      <IPButton
        variant="nav"
        :to="{ name: 'studies' }"
      >
        {{ t('common.menu.home') }}
      </IPButton>

      <!-- Пользователи -->
      <IPButton
        v-if="check('users_list')"
        variant="nav"
        :to="{ name: 'modal-users' }"
      >
        {{ t('common.menu.users') }}
      </IPButton>

      <!-- Профиль -->
      <IPButton
        variant="nav"
        :to="{ name: 'modal-profile' }"
      >
        {{ t('profile.menu.profile') }}
      </IPButton>

      <LanguageSwitcher direction="right"/>
      <!-- Выйти -->
      <IPButton
        class="mobile-logout"
        variant="nav"
        @click="handleLogout"
      >
        {{ t('profile.menu.logout') }}
      </IPButton>
    </div>
  </div>
</div>
</template>

<script>
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { check } from '@/core/services/acl';

import IPButton from '@/core/ui/buttons/IPButton.vue';

import IconBurger from '@/core/ui/icons/IconBurger.vue';
import IconClose from '@/core/ui/icons/IconClose.vue';
import LanguageSwitcher from '@/components/site/LanguageSwitcher.vue';

export default {
  name: 'MobileNav',

  emits: ['logout'],

  components: {
    IPButton,

    IconBurger,
    IconClose,
    LanguageSwitcher,
  },

  setup(props, { emit }) {
    const route = useRoute();
    const { t } = useI18n();
    const isOpened = ref(false);

    function open() {
      isOpened.value = true;
    }

    function close() {
      isOpened.value = false;
    }

    watch(() => route.name, () => {
      isOpened.value = false;
    });

    function handleLogout() {
      emit('logout');
    }

    return {
      t,
      isOpened,
      open,
      close,
      check,
      handleLogout,
    };
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/kit/fonts";
@import "@/core/ui/assets/styles/kit/colors";

.mobile-nav {
  margin-left: auto;

  .mobile-logout {
    text-align: left;
  }

  &__content {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: $color_background_grey;
    box-sizing: border-box;

    z-index: 200;
  }

  &__close {
    position: absolute;
    top: 33px;
    right: 33px;
  }

  &__items {
    margin-top: 94px;
    display: flex;
    flex-direction: column;

    .ip-button, .language-switcher {
      margin-left: 48px;
    }
  }
}
</style>
