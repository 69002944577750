<template>
  <IPLoader v-if="isLoading"/>

  <router-view v-else/>

  <IPNotifications/>
</template>

<script>
import { inject, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import IPNotifications from '@/core/plugins/notification/IPNotification.vue';
import IPLoader from '@/core/ui/IPLoader.vue';

export default {
  components: {
    IPNotifications,
    IPLoader,
  },

  setup() {
    const store = useStore();
    const { t } = useI18n();

    const notification = inject('notification');

    const isLoading = computed(() => store.state.application.loading);

    const errorMessage = computed(() => store.state.errorHandler.message);
    watch(
      () => errorMessage.value,
      (value) => {
        notification(
          t('common.labels.error'),
          t(value),
          'error',
        );
      },
    );

    return {
      isLoading,
    };
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/application";
</style>
