<template>
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="6" width="16" height="2" rx="1" fill="white"/>
    <rect width="16" height="2" rx="1" fill="white"/>
    <rect y="12" width="16" height="2" rx="1" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'IconBurger',
};
</script>
