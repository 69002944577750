<template>
  <div class="study-expand" v-if="!mob">
    <div class="row1" v-if="!mob">
      <div
        class="ym-hide-content"
        @click="sort('ts_number')"
        @keydown="sort('ts_number')"
      >
        {{ t('study.labels.trial_sites') }}
      </div>
      <IPRadioBtn v-model="ActiveTab" :options="tabs"/>
      <div
        class="ym-hide-content"
        @click="sort('total_count')"
        @keydown="sort('total_count')"
      >
        {{ t('common.labels.total') }}
      </div>
    </div>

    <div class="study-expand__warning-toggle" v-if="ActiveTab === 'aeSaeItems'">
      <IPToggle
        id="study-expand__warning-toggle"
        :label="t('common.attention.toggle_label')"
        v-model="showWarning"
      />
    </div>

    <div class="study-expand__warning-info" v-if="ActiveTab === 'aeSaeItems' && showWarning">
      <p>
        <b>{{ t('common.attention.coefficient_label') }}</b>
        {{ t('common.attention.coefficient_explanation') }}
      </p>
    </div>

    <template v-if="showWarning && ActiveTab === 'aeSaeItems'">
      <div
        class="study-expand__row"
        v-for="item in attentionItems"
        :key="item.id"
      >
        <div class="study-expand__column study-expand__column_number ym-hide-content">
          {{ item.number }}
        </div>
        <div
          class="study-expand__column study-expand__column_name ym-hide-content"
          :title="item.name"
        >
          {{ item.principal_investigator }}
        </div>
        <div class="study-expand__column ym-hide-content">
          <IPAttribute :type="getTypeStatus(item.status)">
            {{ t(`common.attention.${item.status}`) }}
          </IPAttribute>
        </div>
      </div>
    </template>

    <template v-else-if="!mob && items && items[ActiveTab]">
      <div
        class="study-expand__row"
        v-for="item in items[ActiveTab].counters"
        :key="item.ts_number"
      >
        <div class="study-expand__column study-expand__column_number ym-hide-content">
          {{ item.ts_number }}
        </div>

        <div
          class="study-expand__column study-expand__column_name ym-hide-content"
          :title="item.ts_name"
          @click="handleOpen(item.ts_id)"
          @keydown="handleOpen(item.ts_id)"
        >
          {{ item.principal_investigator }}
        </div>

        <div class="study-expand__column ym-hide-content">
          <IPStatsProgress
            minimal
            :items="item.counters"
            :max-total="ActiveTab === 'activeItems' ? items[ActiveTab].max_count : item.total_count"
            :total="item.total_count"
            :title="item.ts_name"
          />
        </div>

        <div class="study-expand__column study-expand__column_total ym-hide-content">
          {{ item.total_count }}
        </div>
      </div>
    </template>

    <IPLoader v-if="request.isProcessing()"/>
  </div>

  <StudyExpandMobile :tabs="tabs" v-else/>
  <PrintStudy :item="study" :expand="tabs"/>
</template>

<script>
import { useRouter } from 'vue-router';
import { isMobile } from '@/core/untils';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { each, find } from 'lodash';

import IPRadioBtn from '@/core/ui/form/IPRadioBtn.vue';

import StudyExpandMobile from '@/components/study/StudyExpand/StudyExpandMobile.vue';
import PrintStudy from '@/components/study/PrintStudy.vue';

import IPStatsProgress from '@/core/ui/stats/IPStatsProgress.vue';
import IPLoader from '@/core/ui/IPLoader.vue';

import IPAttribute from '@/core/ui/IPAttribute';
import IPToggle from '@/core/ui/IPToggle';

import StudyExpandRequest from '@/requests/study/StudyExpandRequest';
import StudyAttentionRequest from '@/requests/study/StudyAttentionRequest';

export default {
  name: 'StudyExpand',

  components: {
    IPRadioBtn,
    IPStatsProgress,
    IPLoader,
    StudyExpandMobile,
    PrintStudy,
    IPAttribute,
    IPToggle,
  },

  props: {
    study: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter();
    const mob = isMobile();
    const { t } = useI18n();

    const ActiveTab = ref('activeItems');
    const tabs = ref([
      {
        label: t('study.buttons.screen'),
        value: 'screenItems',
      },
      {
        label: t('study.buttons.active'),
        value: 'activeItems',
      },
      {
        label: t('study.buttons.ae_sae'),
        value: 'aeSaeItems',
      },
    ]);

    const items = ref([]);

    const request = ref(new StudyExpandRequest(props.study.id));
    async function loadItems(sortField = 'total_count') {
      request.value.setAttribute('sort_field', sortField);
      request.value.setAttribute(
        'sort_direction',
        request.value.attributes.sort_direction.value === 'asc' ? 'desc' : 'asc',
      );

      const response = await request.value.sendRequest();
      if (response.code === 200) {
        items.value = response.data;
        each(response.data, (items, key) => {
          const currentTab = find(tabs.value, (tab) => tab.value === key);
          currentTab.items = items;
        });
      }
    }
    loadItems();

    const showWarning = ref(false);
    const attentionItems = ref([]);
    const attentionRequest = ref(new StudyAttentionRequest(props.study.id));
    async function loadAttention() {
      const response = await attentionRequest.value.sendRequest();
      if (response.code === 200) {
        attentionItems.value = response.data;
      }
    }
    loadAttention();

    function sort(field) {
      loadItems(field);
    }

    watch(
      () => props.study.id,
      (value) => {
        request.value = new StudyExpandRequest(value);
        attentionRequest.value = new StudyAttentionRequest(value);
        loadItems();
        loadAttention();
      },
    );

    function handleOpen(id) {
      router.push({ name: 'modal-trial', params: { id } });
    }

    const getTypeStatus = (item) => {
      switch (item) {
        case 'high_coefficient':
          return 'alarm';
        case 'low_coefficient':
          return 'warning';
        case 'normal_coefficient':
          return 'success';
        case 'no_data':
        default:
          return 'info';
      }
    };

    return {
      t,
      ActiveTab,
      tabs,
      items,
      request,
      loadItems,
      sort,
      mob,
      handleOpen,

      showWarning,
      attentionItems,
      getTypeStatus,
    };
  },
};
</script>

<style lang="scss">
.study-expand {
  position: relative;
  font-size: 14px;
  background: #FFFFFF;
  border-radius: 23px;
  padding: 24px;
  min-height: 50px;

  &__row {
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__warning-toggle {
    margin-bottom: 24px;
  }

  &__column {
    flex: 1;
  }

  &__column_total {
    flex: 0;
    margin-left: 40px;
  }

  &__warning-info {
    font-style: normal;
    font-size: 16px;
    line-height: 16px;

    padding: 16px;
    background: #F3F3F4;

    width: 474px;

    border-radius: 8px;

    margin: 24px 0;

    p {
      width: 342px;
    }
  }

  &__column_number {
    flex: 0 1 25px;
    margin-right: 16px;

    color: #C0C2C7;
  }

  &__column_name {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;

    flex: 0 1 184px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #454B59;
    cursor: pointer;
    margin-right: 24px;
  }

  .row1 {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    div {
      cursor: pointer;
      margin-right: 24px;
    }

    div:last-child {
      margin-left: auto;
      margin-right: 0;
    }
  }
}
</style>
