<template>
  <div
    :class="className"
    @mousemove="hovered"
    @mouseleave="hovered"
    @blur="hovered"
  >
    <IPTooltip :event="tooltipEvent" v-if="minimal">
      <div class="ip-stats-progress__legend-title">
        {{ title }}
      </div>
      <div class="ip-stats-progress__legend">
        <div class="ip-stats-progress__legend-item ip-stats-progress__legend-item_total">
          {{ t('common.labels.total') }} {{ total }}
        </div>
        <div class="ip-stats-progress__legend-item" v-for="item in items" :key="item.name">
          <div class="ip-stats-progress__legend-color" :style="`background: ${item.color}`"></div>
          <div class="ip-stats-progress__legend-label">
            {{ t(`study.status.${item.name}`) }} {{ item.count }}
          </div>
        </div>
      </div>
    </IPTooltip>

    <div class="row">
      <div class="ip-stats-progress__container">
        <template v-for="item in items" :key="item.name">
          <div
            v-if="item.count > 0"
            class="ip-stats-progress__item"
            :style="`width: ${getPercent(item.count)}%; background: ${item.color}`"
            :title="t(`study.status.${item.name}`) + ': ' + item.count"
          >
            <span v-if="!minimal">{{ item.count }}</span>
          </div>
        </template>
      </div>
    </div>

    <div class="row" v-if="!minimal || showLegend">
      <div class="ip-stats-progress__legend-item" v-for="item in items" :key="item.name">
        <div class="ip-stats-progress__legend-color" :style="`background: ${item.color}`"></div>
        <div class="ip-stats-progress__legend-label">
          {{ t(`study.status.${item.name}`) }} <span v-if="showLegendCount">{{ item.count }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';

import IPTooltip from '@/core/ui/IPTooltip.vue';

export default {
  name: 'IPStatsProgress',

  components: {
    IPTooltip,
  },

  props: {
    title: {
      type: String,
    },

    items: {
      type: Array,
      required: true,
    },

    total: {
      type: Number,
    },

    maxTotal: {
      type: Number,
      required: true,
    },

    minimal: {
      type: Boolean,
      default: false,
    },

    showLegend: {
      type: Boolean,
      default: false,
    },

    showLegendCount: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const className = computed(() => {
      const styles = ['ip-stats-progress'];

      if (props.minimal) {
        styles.push('ip-stats-progress_minimal');
      }

      return styles;
    });

    function getPercent(item) {
      return (item / props.maxTotal) * 100;
    }

    const tooltipEvent = ref(null);

    function hovered(e) {
      tooltipEvent.value = e;
    }

    return {
      className,
      getPercent,
      hovered,
      tooltipEvent,
      t,
    };
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/kit/fonts";

.ip-stats-progress {
  position: relative;
  border-radius: 15px;

  &_minimal {
    .ip-stats-progress__container {
      height: 8px;
    }

    .ip-stats-progress__item {
      padding: 0;
    }

    &__legend-item {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__container {
    overflow: hidden;
    border-radius: 15px;
    position: relative;
    display: flex;
    width: 100%;
    height: 28px;
    background: #E6EAEE;
  }

  &__end {
    width: 4px;
    height: 100%;
    background: #E6EAEE;
  }

  &__legend-title {
    @include font_subtitle;

    margin-bottom: 24px;
  }

  &__legend-item {
    user-select: none;
    @include font_details;
    display: flex;
    align-items: center;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__legend-item_total {
    @include font_subtitle;
  }

  &__legend-color {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
  }

  &__item {
    user-select: none;
    text-align: center;
    line-height: 28px;
    min-width: fit-content;
    padding: 0 10px;
  }

  &__value-hidden {
    width: 1px;
    height: 100%;
  }

  &__item:last-child {
    position: relative;

    .ip-stats-progress__end {
      display: block;
    }
  }

  &__total {
    color: #9D9D9D;
    margin-left: 24px;
  }

  .row {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
