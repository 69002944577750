import Store from '@/store';
import { each } from 'lodash';
import UserInfoRequest from '@/requests/auth/UserInfoRequest';

export function setPermissions(role) {
  const data = {
    sp_admin: ['users_list'],
  };

  Store.commit('user/permissions', data[role] || []);
}

export function getPermissions() {
  const { permissions } = Store.state.user;

  return permissions;
}

export function check(name) {
  return getPermissions().includes(name);
}

export async function can(rules = null) {
  if (rules === null) {
    return true;
  }

  const token = localStorage.getItem('application.auth.token');
  if (token !== null) {
    Store.commit('user/token', token);

    const request = new UserInfoRequest();
    const response = await request.sendRequest();
    if (response.code === 200) {
      Store.commit('user/info', response.data);
      setPermissions(response.data.role);
    } else {
      Store.commit('user/info', null);
      Store.commit('user/token', null);
      localStorage.removeItem('application.auth.token');
    }
  }

  const { info } = Store.state.user;

  let isCan = false;
  each(rules, (rule) => {
    if (rule === '?') {
      isCan = info === null;
    } else if (rule === '@') {
      isCan = info !== null;
    } else {
      isCan = info !== null && info.role === rule;
    }

    return !isCan;
  });

  return isCan;
}

export default {
  can,
};
