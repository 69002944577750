<template>
<div :class="className" @click="toggle" @keydown="toggle" v-click-outside="close">
  <div class="ip-dropdown__title">
    <slot/>

    <IconDropdown
      v-if="!minimal"
      :direction="isOpened ? 'up' : 'down'"
      class="ip-dropdown__toggle"
    />
  </div>
  <div class="ip-dropdown__items" v-show="isOpened"><slot name="items"/></div>
</div>
</template>

<script>
import { ref, computed } from 'vue';

import IconDropdown from '@/core/ui/icons/IconDropdown.vue';

export default {
  name: 'IPDropdown',

  props: {
    minimal: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    IconDropdown,
  },

  setup(props) {
    const className = computed(() => {
      const styles = ['ip-dropdown'];

      if (props.minimal) {
        styles.push('ip-dropdown_minimal');
      }

      return styles;
    });

    const isOpened = ref(false);

    function toggle() {
      isOpened.value = !isOpened.value;
    }

    function close() {
      isOpened.value = false;
    }

    return {
      className,
      isOpened,
      toggle,
      close,
    };
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/kit/fonts";
@import "@/core/ui/assets/styles/kit/colors";

.ip-dropdown {
  @include font_buttons;

  position: relative;
  cursor: pointer;
  user-select: none;
  border: 1px solid $color_mid_grey;
  border-radius: 10px;
  padding: 9px 20px;
  box-sizing: border-box;

  &_minimal {
    border: none;
    padding: 0;

    .ip-dropdown__title {
      svg {
        margin-right: 0;
      }
    }

    .ip-dropdown__items {
      border: 1px solid $color_mid_grey;
      border-radius: 6px;
    }
  }

  &__title {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;

      &.ip-dropdown__toggle {
        margin-right: 0;
        margin-left: 8px;
      }
    }
  }

  &__items {
    position: absolute;
    background: $color_white;
    width: fit-content;
    min-width: 100%;
    right: 0;
    border: 1px solid $color_mid_grey;
    border-top: none;
    box-sizing: border-box;
    padding: 9px 20px;
    border-radius: 0 0 10px 10px;
    z-index: 90000;
  }

  &__item {
    padding: 12px 0;

    &:hover {
      color: $color_main_accent;
    }
  }
}
</style>
