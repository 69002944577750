<template>
  <IPModal
    size="sm"
    class="modal-users"
    :is-loading="request.isProcessing()"
  >
    <template v-slot:title>{{ t('employee.modals.users') }}</template>
    <template v-slot:action>
      <IPButton @click="addUser" variant="primary-outline" size="sm">
        {{ t('common.buttons.create') }}
      </IPButton>
    </template>

    <IPEmptyData
      v-if="!request.isProcessing() && items.length === 0"
    >
      {{ t('employee.messages.empty_list') }}
    </IPEmptyData>

    <div class="sponsor-users__items">
      <div
        v-for="item in items"
        :key="item.id"
        class="sponsor-users__item ym-hide-content"
      >
        <!-- Edit -->
        <IPButton
          variant="link"
          @click="editItem(item)"
        >
          {{ getUserName(item) }}
        </IPButton>

        <!-- Studies -->
        <IPButton
          @click="openStudies(item)"
          variant="primary-outline"
          size="sm"
          :round="false"
        >
          {{ item.studies_count }}
        </IPButton>

        <!-- Remove -->
        <IPButton
          @click="removeItem(item)"
          variant="link"
        >
          <IconTrash/>
        </IPButton>
      </div>
    </div>
  </IPModal>
</template>

<script>
import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import IPModal from '@/core/ui/IPModal.vue';
import IPButton from '@/core/ui/buttons/IPButton.vue';
import IconTrash from '@/core/ui/icons/IconTrash.vue';
import IPEmptyData from '@/core/ui/IPEmptyData.vue';

import EmployeesListRequest from '@/requests/employees/EmployeesListRequest';
import SponsorUserDeleteRequest from '@/requests/employees/SponsorUserDeleteRequest';

export default {
  name: 'UsersList',

  components: {
    IPEmptyData,
    IPModal,
    IPButton,
    IconTrash,
  },

  setup() {
    const { t } = useI18n();
    const notification = inject('notification');
    const viewMode = inject('sponsorUsersModalView');

    const items = ref([]);

    const request = ref(new EmployeesListRequest());
    async function loadItems() {
      const response = await request.value.sendRequest();
      if (response.code === 200) {
        items.value = response.data;
      }
    }
    loadItems();

    function addUser() {
      if (items.value.length >= 5) {
        notification(
          t('common.exception.failed'),
          t('employee.exception.max_count', { count: 5 }),
          'error',
        );
      } else {
        viewMode.view = 'item';
      }
    }

    // Редактирование
    function editItem(item) {
      viewMode.view = 'item';
      viewMode.item = item;
    }

    // Удаление
    const requestRemove = ref(null);
    const confirm = inject('confirm');
    async function removeItem(item) {
      requestRemove.value = new SponsorUserDeleteRequest(item.id);

      const isConfirmed = await confirm({
        title: t('employee.confirms.delete.title'),
        description: t('employee.confirms.delete.description', { email: item.email }),
      });

      if (isConfirmed) {
        const response = await requestRemove.value.sendRequest();
        if (response.code === 200) {
          notification(
            t('user.messages.deleted.title'),
            t('user.messages.deleted.description', { name: item.email }),
            'success',
          );
          await loadItems();
        }
      }
    }

    // Список доступов
    function openStudies(item) {
      viewMode.view = 'studies';
      viewMode.item = item;
    }

    function getUserName(item) {
      let label = `${item.last_name} ${item.first_name[0]}`;
      if (item.patronymic_name) {
        label += `.${item.patronymic_name[0]}`;
      }

      return label;
    }

    return {
      t,
      addUser,
      items,
      request,
      editItem,
      removeItem,
      openStudies,
      getUserName,
    };
  },
};
</script>

<style lang="scss">
.modal-users {
  .sponsor-users__items {
    .sponsor-users__item {
      display: flex;
      align-items: center;
      margin: 15px 0;

      .ip-button {
        margin-right: 20px;
      }

      .ip-button:first-child {
        margin-right: auto;
      }

      .ip-button:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
