<template>
  <UsersList
    v-if="viewMode.view === 'list'"
    v-model="show"
  />
  <UsersItem
    v-else-if="viewMode.view === 'item'"
    v-model="show"
    :item="viewMode.item"
  />
  <UsersStudies
    v-else-if="viewMode.view === 'studies'"
    v-model="show"
    :item="viewMode.item"
  />
</template>

<script>
import {
  reactive,
  ref,
  provide,
  inject,
} from 'vue';

import UsersList from '@/components/users/modals/UsersList.vue';
import UsersItem from '@/components/users/modals/UsersItem.vue';
import UsersStudies from '@/components/users/modals/UsersStudies.vue';

export default {
  name: 'SponsorUsers',

  components: {
    UsersList,
    UsersItem,
    UsersStudies,
  },

  setup() {
    const show = ref(true);
    const modalsList = inject('modalsList');

    const viewMode = reactive({ view: 'list', item: null });
    provide('sponsorUsersModalView', viewMode);

    return {
      show,
      viewMode,
      modalsList,
    };
  },
};
</script>

<style lang="scss">

</style>
