<template>
  <IPModal
    size="sm"
    class="modal-user-studies"
    @close="goBack"
    @keydown.esc="goBack"
  >
    <template v-slot:title>
      <div @click="goBack" @keydown.esc="goBack"><IconBack/></div>
      <div>{{ t('study.modals.studies') }}</div>
    </template>

    <IPLoader v-if="request.isProcessing()"/>

    <IPForm
      v-if="request"
      submit-text="common.buttons.confirm"
      class="sponsor-modify-form"
      @submit="submit"
      :request="request"
    >
      <IPCheckbox
        class="ym-hide-content"
        v-for="item in items"
        v-model="item.is_linked"
        :key="item.id"
      >
        {{ item.name }}
      </IPCheckbox>
    </IPForm>
  </IPModal>
</template>

<script>
import { ref, inject } from 'vue';
import { map, filter } from 'lodash';
import { useI18n } from 'vue-i18n';

import IconBack from '@/core/ui/icons/IconBack.vue';
import IPLoader from '@/core/ui/IPLoader.vue';
import IPModal from '@/core/ui/IPModal.vue';
import IPCheckbox from '@/core/ui/form/IPCheckbox.vue';
import IPForm from '@/core/ui/form/IPForm.vue';

import EmployeesStudiesListRequest from '@/requests/employees/EmployeesStudiesListRequest';
import EmployeesStudiesUpdateRequest from '@/requests/employees/EmployeesStudiesUpdateRequest';

export default {
  name: 'UsersStudies',

  components: {
    IPLoader,
    IPModal,
    IPCheckbox,
    IPForm,
    IconBack,
  },

  props: {
    item: {
      type: Object,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const notification = inject('notification');
    const viewMode = inject('sponsorUsersModalView');

    const items = ref([]);

    const request = ref(new EmployeesStudiesListRequest(props.item.id));
    async function loadItems() {
      const response = await request.value.sendRequest();
      if (response.code === 200) {
        items.value = response.data;
      }
    }
    loadItems();

    function goBack() {
      viewMode.view = 'list';
      viewMode.item = null;
    }

    const submitRequest = ref(new EmployeesStudiesUpdateRequest(props.item.id));
    async function submit() {
      const ids = map(filter(items.value, (item) => item.is_linked), (item) => item.id);

      submitRequest.value.setAttribute('studies', ids);

      const response = await submitRequest.value.sendRequest();
      if (response.code === 200) {
        notification(
          t('study.messages.linked.title'),
          t('study.messages.linked.description'),
          'success',
        );
        goBack();
      }
    }

    return {
      t,
      items,
      request,
      goBack,
      submit,
    };
  },
};
</script>

<style lang="scss">
.modal-user-studies {
  .ip-modal__title {
    display: flex;
    justify-content: left;

    div:first-child {
      cursor: pointer;
    }
  }
}
</style>
