export default {
  namespaced: true,

  state: {
    forgotEmail: null,
    loading: true,
  },

  mutations: {
    forgotEmail(state, value) {
      state.forgotEmail = value;
    },
    loading(state, value) {
      state.loading = value;
    },
  },
};
