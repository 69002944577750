export function isMobile() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
}

export function userLabel(user) {
  let label = `${user.value?.last_name} ${user.value?.first_name[0]}`;
  if (user.value?.patronymic_name) {
    label += `.${user.value.patronymic_name[0]}.`;
  }

  return label;
}

export default {
  isMobile,
  userLabel,
};
