<template>
<div class="study-list">
  <StudyItem v-if="selectedItem" v-model="selectedItem" :items="items"/>

  <StudyExpand v-if="selectedItem" :study="selectedItem" />

  <router-view></router-view>
</div>
</template>

<script>
import { first, find } from 'lodash';
import { ref, watch } from 'vue';

import StudyItem from '@/components/study/StudyItem.vue';
import StudyExpand from '@/components/study/StudyExpand.vue';

import StudyListRequest from '@/requests/study/StudyListRequest';

export default {
  name: 'StudyList',

  components: {
    StudyItem,
    StudyExpand,
  },

  setup() {
    const items = ref([]);
    const selectedItem = ref(null);
    watch(() => selectedItem.value, (value) => {
      if (value) {
        localStorage.setItem('application.selectedStudy', value.id);
      }
    });

    const request = new StudyListRequest();
    async function loadItems() {
      const response = await request.sendRequest();
      if (response.code === 200) {
        items.value = response.data;
        if (items.value.length > 0) {
          const localItem = localStorage.getItem('application.selectedStudy');

          let itemInStorage = null;
          if (localItem) {
            itemInStorage = find(items.value, (item) => item.id === Number(localItem));
          }

          if (itemInStorage) {
            selectedItem.value = itemInStorage;
          } else {
            selectedItem.value = first(items.value);
          }
        }
      }
    }
    loadItems();

    return {
      items,
      selectedItem,
    };
  },
};
</script>

<style lang="scss">
.study-list {
  .ip-spoiler {
    margin-bottom: 32px;
  }

  .study-expand {
    margin-top: 24px;
  }
}
</style>
