<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_5503_17286"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16"
    >
      <path
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2
        11.3137 4.68629 14 8 14Z"
        :stroke="colors[type]"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 5V8"
        :stroke="colors[type]"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 10H8.00667"
        :stroke="colors[type]"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </mask>
    <g mask="url(#mask0_5503_17286)">
      <rect width="16" height="16" :fill="colors[type]"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconAttention',

  props: {
    type: {
      type: String,
      default: 'info',
      validate(value) {
        return [
          'alarm',
          'warning',
          'success',
          'info',
        ].includes(value);
      },
    },
  },

  setup() {
    const colors = {
      alarm: '#FF1F00',
      warning: '#FFA63B',
      success: '#00C853',
      info: '#161E2F',
    };

    return {
      colors,
    };
  },
};
</script>
