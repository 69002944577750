<template>
  <div
    :class="className"
    v-click-outside="close"
    @click="toggle"
    @keyup="toggle"
  >
    <div class="ip-select__container">
      <div class="ip-select__box">
        <div v-if="error" class="ip-select__label">{{ t(error) }}</div>
        <div v-else-if="label" class="ip-select__label">{{ t(label) }}</div>

        <div class="ip-select__value">{{ value }}</div>
      </div>

      <IconDropdown
        :direction="isOpened ? 'up' : 'down'"
        class="ip-select__toggle"
      />
    </div>

    <!-- Options -->
    <div class="ip-select__options" v-show="isOpened">
      <div
        v-for="option in options"
        class="ip-select__option"
        :class="{ 'ip-select__option_selected': isSelected(option) }"
        @click="select(option)"
        @keydown="select(option)"
        :key="option[attributeValue]"
      >
        <IconSelected v-if="isSelected(option)"/>
        {{ option[attributeLabel] }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { find } from 'lodash';

import IconDropdown from '@/core/ui/icons/IconDropdown.vue';
import IconSelected from '@/core/ui/icons/IconSelected.vue';

export default {
  name: 'IPSelect',

  emits: ['select'],

  components: {
    IconDropdown,
    IconSelected,
  },

  props: {
    modelValue: null,
    returnFullObject: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
    },

    error: {
      type: String,
    },

    options: {
      type: Array,
      required: true,
    },

    attributeValue: {
      type: String,
      default: 'value',
    },

    attributeLabel: {
      type: String,
      default: 'label',
    },

    placeholder: {
      type: String,
    },

    useSearch: {
      type: Boolean,
      default: false,
    },

    minimal: {
      type: Boolean,
      default: false,
    },

    form: {
      type: Boolean,
      default: true,
    },

    direction: {
      type: String,
      default: 'left',
      validator(value) {
        return ['left', 'right'].includes(value);
      },
    },

    mob: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const isOpened = ref(false);
    const { t } = useI18n();

    const className = computed(() => {
      const styles = ['ip-select', `ip-select_direction-${props.direction}`];

      if (props.minimal) {
        styles.push('ip-select_minimal');
      }

      if (props.form) {
        styles.push('ip-select_form');
      }

      if (props.error) {
        styles.push('ip-select_invalid');
      }

      if (props.mob) {
        styles.push('ip-select_mobile');
      }

      return styles;
    });

    function toggle() {
      isOpened.value = !isOpened.value;
    }

    function close() {
      isOpened.value = false;
    }

    const value = computed(() => {
      let item;
      const attribute = props.attributeValue;
      if (props.returnFullObject) {
        item = find(props.options, (item) => item === props.modelValue);
      } else {
        item = find(props.options, (item) => item[attribute] === props.modelValue);
      }
      return item ? item[props.attributeLabel] : null;
    });

    function isSelected(item) {
      if (props.returnFullObject) {
        return item === props.modelValue;
      }

      return item[props.attributeValue] === props.modelValue;
    }

    function select(option) {
      if (props.returnFullObject) {
        emit('update:modelValue', option);
        emit('select', option);
      } else {
        emit('update:modelValue', option[props.attributeValue]);
        emit('select', option[props.attributeValue]);
      }
    }

    return {
      t,
      className,
      isOpened,
      value,
      toggle,
      close,
      select,
      isSelected,
    };
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/kit/fonts";
@import "@/core/ui/assets/styles/kit/colors";
@import "@/core/ui/assets/styles/kit/shadows";

.ip-select {
  position: relative;
  user-select: none;
  width: fit-content;

  &_invalid {
    .ip-select__container {
      border-color: #EE5B6D;
    }

    .ip-select__label {
      color: #EE5B6D;
    }
  }

  &_direction-left > &__options {
    right: 0;
  }

  &_direction-right > &__options {
    left: 0;
  }

  &__value {
    @include font_header;

    cursor: pointer;
    color: $color_main_accent;
    display: flex;
    align-items: center;

    .ip-select__toggle {
      margin-left: 9px;
    }
  }

  &_mobile {
    @include font_buttons;
    background: #FFFFFF;
    position: absolute;
    top: -60px;
    left: 0;
    width: 90%;
    height: 20px;
    padding: 15px;
    border-radius: 10px;
  }

  /* Minimal */
  &_minimal {
    .ip-select__value {
      @include font_buttons;

      color: $color_main_black;
    }

    .ip-select__container {
      display: flex;
    }

    .ip-select__toggle {
      margin-left: 3px;
    }
  }

  .ip-select__container {
    display: flex;
    align-items: center;
  }

  .ip-select__box {
    margin-right: 8px;
  }

  /* Form */
  &_form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    height: 48px;
    cursor: pointer;
    user-select: none;
    border: 1px solid #ADADAD;
    border-radius: 10px;
    padding: 0 16px;
    box-sizing: border-box;

    .ip-select__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .ip-select__value {
      @include font_inputs;

      color: $color_main_black;

      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  &_form > &__options {
    position: absolute;
    background: $color_white;
    width: 100%;
    height: fit-content;
    right: 0;
    bottom: 0;
    border: 1px solid #ADADAD;
    border-top: none;
    box-sizing: border-box;
    padding: 9px 20px;
    border-radius: 0 0 10px 10px;
    z-index: 90000;
    box-shadow: none;

    .ip-select__option {
      @include font_inputs;
      overflow: hidden;
    }
  }

  &__options {
    position: absolute;
    top: 35px;
    background: #FFFFFF;
    padding: 24px;
    width: fit-content;

    border: 1px solid #1B85FF;
    border-radius: 10px;
    z-index: 90000;

    @include shadow_light;
  }

  &__option {
    @include font_subtitle;

    white-space: nowrap;
    cursor: pointer;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: $color_main_accent;
    }
  }

  &__option_selected {
    color: $color_main_accent;
  }
}
</style>
