<template>
<div class="ip-tag">
  <slot/>
</div>
</template>

<script>
export default {
  name: 'IPTag',
};
</script>

<style lang="scss">
.ip-tag {
  height: fit-content;
  user-select: none;
  background: #F9F9F9;
  border: 1px solid #F9F9F9;
  border-radius: 10px;
  padding: 10px 25px;

  &__value {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
  }

  &__label {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}
</style>
