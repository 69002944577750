<template>
  <div
    id="test"
    class="ip-tooltip"
    v-if="event && event.type === 'mousemove'"
    ref="elTooltip"
    :style="styleItem"
  >
    <slot/>
  </div>
</template>

<script>
import { ref, watch, nextTick } from 'vue';

export default {
  name: 'IPTooltip',

  props: {
    event: null,
  },

  setup(props) {
    const elTooltip = ref(null);
    const styleItem = ref(null);
    const mouseX = ref(null);
    const mouseY = ref(null);

    const changePosition = () => {
      const rectTooltip = elTooltip.value.getBoundingClientRect();
      mouseX.value = props.event.clientX + 16;
      mouseY.value = props.event.clientY + 16;
      if (rectTooltip.bottom > window.innerHeight) {
        mouseY.value = window.innerHeight - rectTooltip.height - 16;
      }

      styleItem.value = `top: ${mouseY.value}px; left: ${mouseX.value}px`;
    };

    watch(() => props.event, () => {
      nextTick(() => {
        changePosition();
      });
    }, { deep: true });

    return {
      elTooltip,
      styleItem,
    };
  },
};
</script>

<style lang="scss">
.ip-tooltip {
  position: fixed;
  z-index: 10000;
  padding: 24px;
  width: fit-content;
  height: fit-content;
  max-width: 280px;
  word-break: break-word;

  background: #FFFFFF;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.05), 0 0 4px rgba(0, 0, 0, 0.05), 0 0 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  bottom: 16px;
  right: 20px;
}
</style>
