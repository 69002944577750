<template>
<div class="study-expand-mobile">
  <div class="study-expand-mobile__head">{{ $t('study.labels.trial_sites') }}</div>

  <Swiper class="study-expand-mobile__items" slidesPerView="auto">
    <SwiperSlide
      class="study-expand-mobile__item"
      v-for="tab in tabs" :key="tab.value"
      style="width: 80vw"
    >
      <div class="study-expand-mobile__title">
        {{ tab.label }}
      </div>

      <template v-if="tab.items && tab.items.counters">
        <template
          v-for="item in tab.items.counters"
          :key="item.ts_number"
        >
          <div class="study-expand-mobile__info">
            <router-link
              :to="{ name: 'modal-trial', params: { id: item.ts_id } }"
              class="study-expand-mobile__ts-name"
            >
              {{ item.ts_number }} {{ item.principal_investigator }}
            </router-link>
            <div class="study-expand-mobile__count">
              {{ item.total_count }}
            </div>
          </div>
          <IPStatsProgress
            minimal
            :items="item.counters"
            :max-total="tab.value === 'activeItems' ? tab.items.max_count : item.total_count"
            :total="item.total_count"
            :title="item.ts_name"
          />
        </template>
      </template>
    </SwiperSlide>
  </Swiper>
</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import IPStatsProgress from '@/core/ui/stats/IPStatsProgress.vue';

export default {
  name: 'StudyExpandMobile',

  components: {
    IPStatsProgress,

    Swiper,
    SwiperSlide,
  },

  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/kit/fonts";
@import "@/core/ui/assets/styles/kit/colors";

.study-expand-mobile {
  border-top: 1px solid #E6EAEE;
  padding-top: 24px;

  &__head {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin: 10px;
  }

  &__title {
    @include font_subtitle;
    margin-bottom: 24px;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
  }

  &__ts-name {
    @include font_title_inputs;
    color: $color-main-accent;
  }

  .swiper-slide {
    width: 50vw;
  }

  &__item {
    width: 80%;
    margin: 8px;
    padding: 16px 8px;
    border-radius: 10px;
    background: #FFFFFF;

    .ip-stats-progress__container {
      margin-bottom: 16px;
      height: 4px;
    }

    .ip-stats-progress__item {
      margin-right: 2px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
