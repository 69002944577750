<template>
  <IPButton
    :variant="variant"
    :type="type"
    :disabled="disabled"
    :adaptive="adaptive"
    :size="size"
    @click="download"
  >
    <slot/>
  </IPButton>
</template>

<script>
import { useStore } from 'vuex';
import config from '@/config';

import IPButton from '@/core/ui/buttons/IPButton.vue';

export default {
  name: 'IPButtonDownload',

  components: {
    IPButton,
  },

  props: {
    url: {
      type: String,
      required: true,
    },

    variant: {
      type: String,
      default: 'default',
      validate(value) {
        return [
          'default',
          'primary',
          'primary-outline',
        ].includes(value);
      },
    },

    type: {
      type: String,
      default: 'button',
      validate(value) {
        return [
          'button',
          'submit',
        ].includes(value);
      },
    },

    size: {
      type: String,
      default: 'md',
      validator(value) {
        return ['sm', 'md'].includes(value);
      },
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    adaptive: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const store = useStore();

    function download() {
      const url = `${config.urls.api}${props.url}?token=${store.state.user.token}`;
      window.location.assign(url);
    }

    return {
      download,
    };
  },
};
</script>
