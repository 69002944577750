<template>
<div :class="className" v-if="modelValue" @mousedown.self="close" @keyup.esc="close">
  <div class="ip-modal__container">
    <div class="ip-modal__header">
      <div class="ip-modal__title"><slot name="title"/></div>
      <div class="ip-modal__action"><slot name="action"/></div>
      <div class="ip-modal__close" @click="close" @keyup.esc="close">
        <iconClose />
      </div>
    </div>
    <div class="ip-modal__content">
      <IPLoader v-if="isLoading"/>
      <slot />
    </div>
  </div>
</div>
</template>

<script>
import { useRouter } from 'vue-router';
import { isMobile } from '@/core/untils';
import { computed, onMounted } from 'vue';

import IPLoader from '@/core/ui/IPLoader.vue';
import IconClose from '@/core/ui/icons/IconClose.vue';

export default {
  name: 'IPModal',

  emits: ['close'],

  components: {
    IPLoader,
    IconClose,
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: 'sm',
      validate(value) {
        return ['sm', 'md', 'lg'].includes(value);
      },
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const router = useRouter();

    onMounted(() => {
      document.body.classList.add('non-scroll');
    });

    const mob = isMobile();
    const className = computed(() => {
      const styles = ['ip-modal', `ip-modal_${props.size}`];

      if (mob) {
        styles.push('ip-modal_mobile');
      }

      return styles;
    });

    function close() {
      router.push({ name: 'studies' });
      emit('update:modelValue', false);
      emit('close');
    }

    return {
      mob,
      className,
      close,
    };
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/kit/fonts";
@import "@/core/ui/assets/styles/kit/colors";
@import "@/core/ui/assets/styles/kit/shadows";

.ip-modal {
  background: rgba(55, 55, 55, 0.25);
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  &_sm {
    .ip-modal__container {
      width: 439px;
    }
  }

  &_md {
    .ip-modal__container {
      width: 993px;
    }
  }

  &__container {
    padding: 24px;
    box-sizing: border-box;
    background: $color_white;
    border-radius: 20px;

    @include shadow_mid;
  }

  &__header {
    margin-bottom: 34px;
    display: flex;
    align-items: center;
  }

  &__title {
    user-select: none;
    @include font_title;

    color: $color_main_black;
    margin-right: 27px;
  }

  &__description {
    @include font_details;

    color: $color_grey_outline;
    margin: 16px 0;
  }

  &__close {
    margin-left: auto;
    cursor: pointer;
  }

  &__content {
    position: relative;
    min-width: 100%;
    min-height: 300px;
    box-sizing: border-box;
  }

  &_mobile {
    align-items: start;
  }

  &_mobile > &__container {
    padding-bottom: 180px;
    border-radius: 0;
    min-height: 100vh;
  }
}
</style>
