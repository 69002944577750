<template>
  <div class="page page_login">
    <div v-if="message" class="page_login__message">
      {{ message }}
    </div>

    <IPForm
      v-if="codeIsValid"
      class="login_form"
      submit-text="common.buttons.confirm"
      @submit="submit"
      :request="request"
    >
      <IPInput
        id="activation_form__email"
        v-model="request.attributes.password.value"
        :error="request.getError('password')"
        label="auth.labels.password"
        type="password"
      />

      <IPInput
        id="activation_form__password"
        v-model="request.attributes.password_repeat.value"
        :error="request.getError('password_repeat')"
        label="auth.labels.password_repeat"
        type="password"
        subtitle="auth.input_subtitles.password"
      />
    </IPForm>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import IPForm from '@/core/ui/form/IPForm.vue';
import IPInput from '@/core/ui/form/IPInput.vue';

import ActivateRequest from '@/requests/auth/ActivateRequest';
import ActivateCheckCodeRequest from '@/requests/auth/ActivateCheckCodeRequest';

export default {
  name: 'ActivatePage',

  components: {
    IPForm,
    IPInput,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const message = ref(null);
    const codeIsValid = ref(false);

    async function checkCode() {
      if (!route.query.code) {
        await router.push({ name: 'login' });
      } else {
        const checkRequest = new ActivateCheckCodeRequest(route.query.code);
        const response = await checkRequest.sendRequest();

        if (response.code === 200) {
          const { first_name, last_name } = response.data;

          codeIsValid.value = true;
          message.value = t('auth.messages.activate', { full_name: `${last_name} ${first_name}` });
        }

        if (response.message) {
          message.value = t(response.message);
        }
      }
    }
    checkCode();

    const request = ref(new ActivateRequest());
    async function submit() {
      request.value.setAttribute('code', route.query.code);
      const response = await request.value.sendRequest();

      if (response.code === 200) {
        store.commit('user/info', response.data.user);
        store.commit('user/token', response.data.auth.token);
        localStorage.setItem('application.auth.token', response.data.auth.token);

        await router.push({ name: 'studies' });
      }
    }

    return {
      message,
      codeIsValid,
      request,
      submit,
      t,
    };
  },
};
</script>

<style lang="scss">
.page_login {
  &__message {
    margin-bottom: 16px;
  }
}
</style>
