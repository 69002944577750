<template>
  <div class="ip-confirm">
    <div class="ip-confirm__container">
      <div class="ip-confirm__title">{{ title }}</div>
      <div class="ip-confirm__description">{{ description }}</div>

      <div class="ip-confirm__actions">
        <IPButton
          variant="primary"
          @click="handleReject"
          size="sm"
        >
          Отмена
        </IPButton>
        <IPButton
          variant="primary-outline"
          @click="handleConfirm"
          size="sm"
        >
          Удалить
        </IPButton>
      </div>
    </div>
  </div>
</template>

<script>
import IPButton from '@/core/ui/buttons/IPButton.vue';

export default {
  name: 'IPConfirm',

  components: {
    IPButton,
  },

  props: {
    title: {
      type: String,
      default: '',
    },

    description: {
      type: String,
      default: '',
    },

    confirmText: {
      type: String,
      default: 'Ок',
    },

    rejectText: {
      type: String,
      default: 'Отмена',
    },

    resolve: {
      type: Function,
    },
  },

  setup(props) {
    const isShown = true;

    function handleConfirm() {
      if (props.resolve) {
        props.resolve(true);
      }
    }

    function handleReject() {
      if (props.resolve) {
        props.resolve(false);
      }
    }

    return {
      handleConfirm,
      handleReject,
      isShown,
    };
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/kit/fonts";
@import "@/core/ui/assets/styles/kit/colors";
@import "@/core/ui/assets/styles/kit/shadows";

.ip-confirm {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  z-index: 900000;

  &__container {
    @include shadow_light;

    text-align: center;
    padding: 24px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 20px;

    width: 454px;
    min-height: 169px;
  }

  &__title {
    color: $color_main_black;

    @include font_title;

    margin-bottom: 16px;
  }

  &__actions {
    margin-top: 32px;

    .ip-button {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
