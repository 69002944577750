import Request from '@/core/services/request';

export default class SponsorUserUpdateRequest extends Request {
  constructor(id) {
    super('POST', `sponsors/employees/${id}`, {
      first_name: {
        value: null,
      },
      last_name: {
        value: null,
      },
      patronymic_name: {
        value: null,
      },
      email: {
        value: null,
      },
      phone: {
        value: null,
      },
    }, 'EmployeeForm');
  }
}
