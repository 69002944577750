<template>
  <IPModal
    v-model="show"
    size="md"
    @close="close"
    class="modal-trial"
    :class="{ 'modal-trial_mob': mob }"
    :is-loading="request.isProcessing()"
  >
    <template v-slot:title>
      <div class="trial-title ym-hide-content" v-if="item && item.name">{{ item.name }}</div>
      <div class="trial-subtitle ym-hide-content"></div>
    </template>

    <template v-if="item">
      <!-- Пациенты -->
      <IPCard v-if="item.patients_info">
        <div class="ip-card__title ym-hide-content">
          <div>{{ t('study.card.patients') }}</div>
          <IPTag>{{ item.patients_info.total_count }}</IPTag>
        </div>

        <div class="ip-card__content ym-hide-content">
          <div class="ip-card__item" v-if="item.patients_info.screening">
            <div class="ip-card__item-label">
              {{ t(`study.status.screening`) }}
            </div>
            <div class="ip-card__item-value">
              {{ item.patients_info.screening }}
            </div>
          </div>

          <div class="ip-card__item ym-hide-content" v-if="item.patients_info.screenfail">
            <div class="ip-card__item-label">
              {{ t(`study.status.screenfail`) }}
            </div>
            <div class="ip-card__item-value">
              {{ item.patients_info.screenfail }}
            </div>
          </div>

          <div class="ip-card__item ym-hide-content" v-if="item.patients_info.enrolled">
            <div class="ip-card__item-label">
              {{ t(`study.status.enrolled`) }}
            </div>
            <div class="ip-card__item-value">
              {{ item.patients_info.enrolled }}
            </div>
          </div>

          <div class="ip-card__item ym-hide-content" v-if="item.patients_info.randomized">
            <div class="ip-card__item-label">
              {{ t(`study.status.randomized`) }}
            </div>
            <div class="ip-card__item-value">
              {{ item.patients_info.randomized }}
            </div>
          </div>

          <div class="ip-card__item ym-hide-content" v-if="item.patients_info.withdrawal">
            <div class="ip-card__item-label">
              {{ t(`study.status.withdrawal`) }}
            </div>
            <div class="ip-card__item-value">
              {{ item.patients_info.withdrawal }}
            </div>
          </div>

          <div class="ip-card__item ym-hide-content" v-if="item.patients_info.completed">
            <div class="ip-card__item-label">
              {{ t(`study.status.completed`) }}
            </div>
            <div class="ip-card__item-value">
              {{ item.patients_info.completed }}
            </div>
          </div>

          <div class="ip-card__item ym-hide-content" v-if="item.patients_info.other">
            <div class="ip-card__item-label">
              {{ t(`study.status.other`) }}
            </div>
            <div class="ip-card__item-value">
              {{ item.patients_info.other }}
            </div>
          </div>

          <IPButtonDownload
            variant="primary-outline"
            :url="getDownloadLink('patient')"
            size="sm"
            :adaptive="mob"
          >
            {{ t('common.buttons.download_table') }}
          </IPButtonDownload>
        </div>
      </IPCard>

      <!-- Нежелательные явления (НЯ) -->
      <IPCard v-if="item.adverse_events_info">
        <div class="ip-card__title ym-hide-content">
          <div>{{ t('study.card.ae') }}</div>
          <IPTag>{{ item.adverse_events_info.total_count }}</IPTag>
        </div>

        <div class="ip-card__content">
          <div
            class="ip-card__item ym-hide-content"
            v-for="status in item.adverse_events_info.statuses"
            :key="status.status"
          >
            <div class="ip-card__item-label">{{ t(`study.status.${status.status}`) }}</div>
            <div class="ip-card__item-value">{{ status.count }}</div>
          </div>

          <IPButtonDownload
            variant="primary-outline"
            :url="getDownloadLink('ae')"
            size="sm"
            :adaptive="mob"
          >
            {{ t('common.buttons.download_table') }}
          </IPButtonDownload>
        </div>

        <!-- Серьезные нежелательные явления (СНЯ) -->
        <div class="ip-card__title ym-hide-content">
          <div>{{ t('study.card.sae') }}</div>
          <IPTag>{{ item.saes_info.primary_count }}</IPTag>
        </div>

        <div class="ip-card__content">
          <div class="ip-card__item ym-hide-content" v-if="item.saes_info.sae_total_count">
            <div class="ip-card__item-label">
              {{ t(`study.status.sae_total_count`) }}
            </div>
            <div class="ip-card__item-value">{{ item.saes_info.sae_total_count }}</div>
          </div>

          <IPButtonDownload
            variant="primary-outline"
            :url="getDownloadLink('sae')"
            size="sm"
            :adaptive="mob"
          >
            {{ t('common.buttons.download_table') }}
          </IPButtonDownload>
        </div>
      </IPCard>

      <!-- Запросы на уточнения -->
      <StudyCard
        :id="$route.params.id"
        :is-study="isStudy"
        title="study.card.queries"
        :items="item.queries_info.statuses"
        :count="item.queries_info.total_count"
        attribute="status"
        exportType="query"
        :mob="mob"
      />

      <!-- Формы -->
      <StudyCard
        :id="$route.params.id"
        :is-study="isStudy"
        title="study.card.forms"
        :items="item.forms_info.types"
        :count="item.forms_info.total_count"
        attribute="type"
        exportType="form"
        :mob="mob"
      />
    </template>
  </IPModal>
</template>

<script>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { isMobile } from '@/core/untils';

import IPModal from '@/core/ui/IPModal.vue';
import IPCard from '@/core/ui/IPCard.vue';
import IPTag from '@/core/ui/IPTag.vue';
import IPButtonDownload from '@/core/ui/buttons/IPButtonDownload.vue';

import StudyCard from '@/components/study/StudyModal/StudyCard.vue';

import TrialViewRequest from '@/requests/trial/TrialViewRequest';
import StudyViewRequest from '@/requests/study/StudyViewRequest';

export default {
  name: 'StudyModal',

  components: {
    IPModal,
    IPCard,
    IPTag,
    IPButtonDownload,

    StudyCard,
  },

  setup() {
    const route = useRoute();
    const show = ref(true);
    const { t } = useI18n();

    const item = ref(null);

    const isStudy = computed(() => route.meta.isStudy);

    const request = ref(null);
    async function loadItems() {
      if (isStudy.value) {
        request.value = new StudyViewRequest(route.params.id);
      } else {
        request.value = new TrialViewRequest(route.params.id);
      }

      const response = await request.value.sendRequest();
      if (response.code === 200) {
        item.value = response.data;
      }
    }
    loadItems();

    function close() {
      item.value = null;
    }

    function getDownloadLink(type) {
      const param = isStudy.value ? 'studies' : 'trials';

      return `export/${param}/${route.params.id}/${type}`;
    }

    return {
      show,
      request,
      item,
      close,
      t,
      getDownloadLink,
      mob: isMobile(),
      isStudy,
    };
  },
};
</script>

<style lang="scss">
.modal-trial {
  .trial-title {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    margin-bottom: 16px;
  }

  .trial-subtitle {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }

  &_mob {
    overflow: scroll;

    .ip-modal__content {
      flex-direction: column;
    }

    .ip-card {
      padding: 5px 0;
      margin: 0;
      background: transparent;
    }
  }

  .ip-modal__content {
    display: flex;
    flex: 1;

    .ip-card {
      flex: 1;
      margin-right: 16px;
      height: fit-content;

      &:last-child {
        margin-right: 0;
      }

      &__content {
        margin: 24px 0;

        &:last-child {
          margin: 0;
        }
      }

      &__title {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 24px;

        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__item-label {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        color: #9D9D9D;
      }

      &__item-value {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;

        color: #373737;
      }
    }
  }
}
</style>
