<template>
  <div class="site-header" :class="{ 'site-header_mobile': mob }">
    <div class="site-header__brand" v-if="mob">
      {{ t('common.brand_mobile') }}
    </div>
    <div class="site-header__brand" v-else>
      {{ t('common.brand') }}
    </div>

    <WebNav v-if="!mob" @logout="handleLogout"/>
    <MobileNav v-else @logout="handleLogout"/>
  </div>
</template>

<script>
import { isMobile } from '@/core/untils';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import WebNav from '@/components/site/nav/WebNav.vue';
import MobileNav from '@/components/site/nav/MobileNav.vue';

import LogoutRequest from '@/requests/auth/LogoutRequest';
import Store from '@/store';

export default {
  name: 'SiteHeader',

  components: {
    WebNav,
    MobileNav,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const mob = isMobile();
    const { t } = useI18n();

    const user = computed(() => store.state.user.info);

    const userLabel = computed(() => {
      let label = `${user.value?.last_name} ${user.value?.first_name[0]}`;
      if (user.value?.patronymic_name) {
        label += `.${user.value.patronymic_name[0]}.`;
      }

      return label;
    });

    const request = new LogoutRequest();

    async function handleLogout() {
      const response = await request.sendRequest();
      if (response.code === 200) {
        Store.commit('user/info', null);
        Store.commit('user/token', null);
        localStorage.removeItem('application.auth.token');
        await router.push({ name: 'login' });
      }
    }

    return {
      t,
      userLabel,
      handleLogout,
      mob,
    };
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/kit/fonts";
@import "@/core/ui/assets/styles/kit/colors";

.site-header {
  background: $color_white;
  border-radius: 0 0 20px 20px;
  height: 82px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  margin-bottom: 16px;

  /* Mobile */
  &_mobile {
    background: #1B85FF;
    border-radius: 0;
    z-index: 200000;
  }

  &_mobile > &__brand {
    color: #FFFFFF;
  }

  &_mobile > &__nav-container {
    position: fixed;
    background: #F3F3F3;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: block;

    z-index: 99999;

    .site-header__nav {
      margin: 0;

      .ip-button_nav {
        margin-right: 0;
      }
    }

    .site-header__nav > a, .site-header__nav > button {
      display: block;
    }
  }

  &_mobile > &__mob-toggle {
    margin-left: auto;
  }

  &__brand {
    @include font_header;
  }
  /* end Mobile */
}
</style>
