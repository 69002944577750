import { createRouter, createWebHistory } from 'vue-router';
import { can } from '@/core/services/acl';

import AuthLayout from '@/views/layouts/AuthLayout.vue';
import MainLayout from '@/views/layouts/MainLayout.vue';

import ActivatePage from '@/views/auth/ActivatePage.vue';
import ForgotPage from '@/views/auth/ForgotPage.vue';
import LoginPage from '@/views/auth/LoginPage.vue';
import RecoveryPage from '@/views/auth/RecoveryPage.vue';
import MessagePage from '@/views/auth/MessagePage.vue';

import StudyList from '@/views/study/index.vue';

import UsersModal from '@/components/users/index.vue';
import ProfileModal from '@/components/profile/ProfileModal.vue';
import StudyModal from '@/components/study/StudyModal.vue';

import TermsPage from '@/views/info/TermsPage.vue';

import NotFound from '@/views/NotFound.vue';

const routes = [
  {
    path: '/',
    name: 'auth',
    component: AuthLayout,
    meta: {
      acl: ['?'],
      redirect: 'studies',
      defaultRoute: 'login',
    },
    children: [
      {
        path: 'activate',
        name: 'activate',
        component: ActivatePage,
        meta: {
          title: 'auth.titles.activate',
        },
      },
      {
        path: 'forgot',
        name: 'forgot',
        component: ForgotPage,
        meta: {
          title: 'auth.titles.forgot',
          description: 'auth.descriptions.forgot',
          back: 'login',
        },
      },
      {
        path: 'login',
        name: 'login',
        component: LoginPage,
        meta: {
          title: 'auth.titles.login',
        },
      },
      {
        path: 'recovery',
        name: 'recovery',
        component: RecoveryPage,
        meta: {
          title: 'auth.titles.forgot',
        },
      },
      {
        path: 'forgot-sent',
        name: 'forgot-sent',
        component: MessagePage,
        meta: {
          title: 'auth.titles.forgot',
        },
      },
    ],
  },
  {
    path: '/',
    name: 'main',
    component: MainLayout,
    meta: {
      acl: ['@'],
      redirect: 'login',
      defaultRoute: 'studies',
    },
    children: [
      {
        path: 'studies',
        name: 'studies',
        component: StudyList,
        meta: {
          acl: ['@'],
          redirect: 'login',
        },
        children: [
          {
            path: 'users',
            name: 'modal-users',
            component: UsersModal,
            meta: {
              acl: ['sp_admin'],
            },
          },
          {
            path: 'profile',
            name: 'modal-profile',
            component: ProfileModal,
          },
          {
            path: ':id',
            name: 'modal-study',
            component: StudyModal,
            meta: {
              isStudy: true,
            },
          },
          {
            path: 'trial/:id',
            name: 'modal-trial',
            component: StudyModal,
            meta: {
              isStudy: false,
            },
          },
        ],
      },
      {
        path: 'terms',
        name: 'terms',
        component: TermsPage,
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.defaultRoute && to.meta.defaultRoute !== to.name && to.path === '/') {
    next({ name: to.meta.defaultRoute });
  }

  if (await can(to.meta.acl || null)) {
    next();
  } else {
    next({ name: to.meta.redirect || 'login' });
  }
});

export default router;
