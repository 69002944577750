<template>
  <div class="page page-not-found" :class="{ 'page-not-found_mobile': mob }">
    <div class="page-not-found__content">
      <div class="page-not-found__text">
        <div class="page-not-found__code">{{ t('common.exception.error_not_found') }}</div>
        <div class="page-not-found__description">{{ t('common.exception.page_not_found') }}</div>

        <IPButton
          :to="{ name: 'studies' }"
          size="sm"
          variant="primary-outline"
        >
          {{ t('common.buttons.main_page') }}
        </IPButton>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { isMobile } from '@/core/untils';

import IPButton from '@/core/ui/buttons/IPButton.vue';

export default {
  name: 'NotFound',

  components: {
    IPButton,
  },

  setup() {
    const mob = isMobile();
    const { t } = useI18n();

    return {
      t,
      mob,
    };
  },
};
</script>

<style lang="scss">
.page-not-found {
  width: 100%;
  height: 100%;

  &_mobile {
    .page-not-found__text {
      margin-left: 15px;
    }
  }

  &__content {
    background: #1B85FF url("@/core/ui/assets/images/404.png");
    height: 576px;
    display: flex;
    align-items: center;
  }

  &__text {
    margin-left: 200px;
    color: #FFFFFF;
  }

  &__code {
    font-weight: 600;
    font-size: 60px;
  }

  &__description {
    font-weight: 400;
    font-size: 30px;
    margin-bottom: 48px;
  }
}
</style>
