<template>
  <div class="study-item" :class="{ 'study-item_mobile': mob }">
    <div class="study-item__header">
      <!-- Select -->
      <div class="row1">
        <IPSelect
          v-model="value"
          :options="items"
          attribute-label="name"
          return-full-object
          :form="false"
          class="ym-hide-content"
          :mob="mob"
        />

        <div class="study-item__included-planned ym-hide-content">
          {{
            t('study.labels.included_planned', {
              included: modelValue.current_enrollment,
              planned: modelValue.expected_total_enrollment,
            })
          }}
        </div>

        <IPButton
          :to="{ name: 'modal-study', params: { id: modelValue.id } }"
          variant="primary-outline"
          size="sm"
        >
          {{ t('common.buttons.more') }}
        </IPButton>

        <IPButton
          v-if="!mob"
          @click="print"
          variant="primary-outline"
          size="sm"
        >
          {{ t('common.buttons.print') }}
        </IPButton>
      </div>

      <div class="row2">
        <IPDateProgress
          :date-from="modelValue.date_planned_start"
          :date-to="modelValue.date_planned_end"
          class="ym-hide-content"
        />
      </div>

      <div class="row3">
        <IPStatsProgress
          :max-total="modelValue.counters.patient_active_statuses.total_count"
          :items="modelValue.counters.patient_active_statuses.counters"
          class="ym-hide-content"
        />
      </div>

      <div class="row4">
        <IPStatsProgress
          :max-total="modelValue.counters.patient_screen_statuses.total_count"
          :items="modelValue.counters.patient_screen_statuses.counters"
          class="ym-hide-content"
        />

        <IPStatsProgress
          :max-total="modelValue.counters.ae_sae.total_count"
          :items="modelValue.counters.ae_sae.counters"
          class="ym-hide-content"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { isMobile } from '@/core/untils';

import IPDateProgress from '@/core/ui/stats/IPDateProgress.vue';
import IPStatsProgress from '@/core/ui/stats/IPStatsProgress.vue';
import IPSelect from '@/core/ui/form/IPSelect.vue';
import IPButton from '@/core/ui/buttons/IPButton.vue';

export default {
  name: 'StudyItem',

  components: {
    IPDateProgress,
    IPStatsProgress,
    IPSelect,
    IPButton,
  },

  props: {
    modelValue: null,

    items: {
      type: Array,
      required: true,
    },
  },

  setup(props, { emit }) {
    const mob = isMobile();
    const { t } = useI18n();

    const value = computed({
      get() {
        return props.modelValue;
      },

      set(value) {
        emit('update:modelValue', value);
      },
    });

    const print = () => {
      window.print();
    };

    return {
      t,
      value,
      mob,
      print,
    };
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/kit/fonts";

.study-item {
  width: 100%;
  background: #FFFFFF;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 24px;

  &_mobile {
    background: none;

    .study-item__header {
      .row1 {
        justify-content: space-between;
      }

      .study-item__included-planned {
        margin: 0;
      }

      .row4 {
        flex-direction: column;

        & > div {
          margin-right: 0;
        }
      }
    }
  }

  &__header {
    .ip-button {
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }

    .row1 {
      position: relative;
      display: flex;
      align-items: center;
    }

    .row2 {
      margin: 24px 0;
    }

    .row4 {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;

      & > div {
        flex: 1 0;
        margin-right: 24px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__included-planned {
    @include font_subtitle;
    margin-left: auto;
    margin-right: 32px;
  }

  &__stats {
    display: flex;
    margin-top: 34px;

    .ip-tag {
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
