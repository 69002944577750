import Request from '@/core/services/request';

export default class ForgotRequest extends Request {
  constructor() {
    super('POST', 'forgot', {
      email: {
        value: null,
      },
    }, 'ForgotForm');
  }
}
