<template>
<div class="ip-card">
  <slot/>
</div>
</template>

<script>
export default {
  name: 'IPCard',
};
</script>

<style lang="scss">
.ip-card {
  background: #F3F3F3;
  border-radius: 10px;
  padding: 26px 16px;

  &__item {

  }
}
</style>
