<template>
  <div class="layout-auth">
    <div class="layout-auth__content">
      <div class="layout-auth__header">
        <router-link v-if="goBack" :to="goBack" class="layout-auth__back-link">
          <IconBack/>
        </router-link>
        <h1 class="layout-auth__title">{{ t(title) }}</h1>
      </div>
      <div v-if="description" class="layout-auth__description">
        {{ t(description) }}
      </div>
      <router-view/>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

import IconBack from '@/core/ui/icons/IconBack.vue';

export default {
  name: 'AuthLayout',

  components: {
    IconBack,
  },

  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const title = computed(() => route.meta.title || '');
    const goBack = computed(() => route.meta.back);
    const description = computed(() => route.meta.description);

    return {
      goBack,
      title,
      description,
      t,
    };
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/kit/fonts";
@import "@/core/ui/assets/styles/kit/colors";
@import "@/core/ui/assets/styles/kit/shadows";

.layout-auth {
  background: #719BB3;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;

    margin-bottom: 24px;
  }

  &__back-link {
    height: 18px;
    margin-right: 6px;
  }

  &__title {
    @include font_title;

    user-select: none;
  }

  &__description {
    margin-bottom: 16px;
  }

  &__content {
    padding: 24px;
    border-radius: 30px;
    background: $color_white;
    width: 391px;
    box-sizing: border-box;

    @include shadow_light;
  }
}
</style>
